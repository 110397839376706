require('./bootstrap');

function overallVMStateToBadge(overallState) {
    switch(overallState) {
        case('POWERED_OFF'):
            return '<span class="px-2 rounded text-light bg-danger">Powered Off</span>';
        case('POWERED_ON'):
            return '<span class="px-2 rounded text-light bg-success">Powered On</span>';
        case('SUSPENDED'):
            return '<span class="px-2 rounded bg-warning">Suspended</span>';
        case('ERROR'):
            return '<span class="px-2 rounded text-light bg-danger">Error</span>';
        case('QUEUED_FOR_DEPLOYMENT'):
            return '<span class="px-2 rounded bg-warning">Queued for Deployment</span>';
        case('QUEUED_FOR_POWER_UP'):
            return '<span class="px-2 rounded bg-warning">Queued for Power Up</span>';
        case('QUEUED_FOR_POWER_DOWN'):
            return '<span class="px-2 rounded bg-warning">Queued for Power Down</span>';
        case('QUEUED_FOR_DELETION'):
            return '<span class="px-2 rounded bg-warning">Queued for Deletion</span>';
        default:
            return '<span class="px-2 rounded text-light bg-danger">Other</span>';
    }
}

window.overallVMStateToBadge = overallVMStateToBadge;

// Replace default button style ('btn btn-secondary') for datatables
$.fn.dataTable.Buttons.defaults.dom.button.className = 'btn btn-sm';
$.fn.dataTable.Buttons.defaults.dom.container.className  = 'dt-buttons flex-wrap';

$(document).ready(function() {
    $('div.alert').not('.alert-important').delay(3000).slideUp(350);
    $('.js-select2').select2({
        theme: 'bootstrap4',
    });
});